import React, { useState, useEffect } from 'react';
import { SignedIn, SignedOut, UserButton, useUser } from "@clerk/clerk-react";
import logo from 'assets/logo.svg';
import CartDropdown from '../Cart/CartDropdown';
import { useCart } from '../../../services/CartService';
import SearchBar from '../Search/SearchBar';
import { Button } from 'components/ui/button';
import { ClipboardList, MapPin, Menu, Package, Search, ShoppingBasket } from 'lucide-react';
import { Badge } from '../../ui/badge';
import SearchModal from '../Modals/SearchModal';
import NoAddressBanner from '../Banner/NoAddressBanner';
import { NavigationMenu, NavigationMenuContent, NavigationMenuItem, NavigationMenuList, NavigationMenuTrigger } from 'components/ui/navigation-menu';
import DropDownMenu from './DropDownMenu';
import { useLocation } from 'react-router-dom';

const NavBar: React.FC = () => {
  const { cart } = useCart();
  const { isSignedIn, user } = useUser();
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(true);
  const cartCount = cart.reduce((sum, item) => sum + item.quantity, 0);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  let location = useLocation()

  const toggleSearchModal = () => {
    setIsSearchModalOpen(!isSearchModalOpen);
  };

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };

  useEffect(() => {
    console.log(location.pathname)

    if (location.pathname === "/boutique/") setShowSearchBar(false)

    const handleScroll = () => {
      const scrollY = window.scrollY || window.pageYOffset;
      const viewportHeight = window.innerHeight;
      if (location.pathname !== "/boutique/" ) {
        if (scrollY > viewportHeight || window.location){
          setShowSearchBar(true);
        } else {
          setShowSearchBar(false);
        }
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location]);
  
  return (
    <header className="fixed inset-x-0 top-0 z-50 bg-white">
      <nav className="flex items-center justify-between p-2 sm:px-6 lg:px-8" aria-label="Global">
        <div className="flex items-center">
          <NavigationMenu>
            <NavigationMenuList>
              <NavigationMenuItem>
                <NavigationMenuTrigger className='p-1 mr-2'><Menu /></NavigationMenuTrigger>
                <NavigationMenuContent>
                  <DropDownMenu />
                </NavigationMenuContent>
              </NavigationMenuItem>
            </NavigationMenuList> 
          </NavigationMenu>
          <a href="https://appro.mullo.fr/boutique/org_2mA6fQMQobyFQFjByLri9x3tTi0" className="-m-1.5 p-1.5 mr-6">
            <span className="sr-only">Mullo</span>
            <img
              className="hidden sm:block h-8 w-auto max-w-[150px] sm:max-w-[200px] lg:max-w-[250px] xl:max-w-[300px] mb-2"
              src={logo}
              alt="Mullo Logo"
              style={{ maxHeight: '100%' }}
            />
          </a>
        </div>
        <div className="flex-1 md:mx-5 lg:mx-20">
          {showSearchBar && (
            <div className="hidden md:block"> {/* Hide on small screens */}
              <SearchBar size='full' />
            </div>
          )}
        </div>
        <div className="flex items-center lg:justify-end space-x-4">
          <Button
            className="md:hidden p-2 rounded-full"
            onClick={toggleSearchModal}
            variant="ghost-secondary"
          >
            <Search size={24} strokeWidth={2} />
          </Button>
          <SignedOut>
            <Button href="/inscription" variant="ghost-primary" className='hidden md:block'>
              Inscription
            </Button>
            <Button href="/connexion" variant="contain-primary">
              Connexion
            </Button>
          </SignedOut>
          <SignedIn>
            <UserButton>
              <UserButton.MenuItems>
                <UserButton.Link
                  label="Mes commandes"
                  labelIcon={<Package size={16} className='text-slate-700' />}
                  href="/dashboard/commandes"
                />
              </UserButton.MenuItems>
              {user?.publicMetadata.userType === 'producer' && <UserButton.MenuItems>
                <UserButton.Link
                  label="Ma mercuriale"
                  labelIcon={<ClipboardList size={16} className='text-slate-700' />}
                  href="/dashboard/mercuriale"
                />
              </UserButton.MenuItems>}
              <UserButton.MenuItems>
                <UserButton.Link
                  label="Mon adresse"
                  labelIcon={<MapPin size={16} className='text-slate-700' />}
                  href="/dashboard/adresse"
                />
              </UserButton.MenuItems>
            </UserButton>
          </SignedIn>
          <Button
            variant="ghost-secondary"
            className='relative rounded-full px-2 hover:bg-primary/20'
            onClick={toggleCart}
          >
            <ShoppingBasket size={30} strokeWidth={1} />
            {cartCount > 0 && (
              <Badge variant="secondary" className="absolute top-[-8px] right-[-8px]">
                {cartCount}
              </Badge>
            )}
          </Button>
        </div>
      </nav>
      {isSignedIn && <NoAddressBanner />}
      {isCartOpen && <CartDropdown closeCart={toggleCart} />}
      {isCartOpen && <div onClick={toggleCart} className="fixed inset-0 bg-black opacity-50 z-40"></div>}

      {/* Add a modal component for the search bar */}
      {isSearchModalOpen && (
        <SearchModal onClose={toggleSearchModal}>
          <SearchBar size="medium" />
        </SearchModal>
      )}

    </header>
  );
};
export default NavBar;
